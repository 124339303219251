<template>
  <div class="">
    <div v-if="loading">
      <p>Loading...</p>
    </div>

    <div v-if="!loading" class="w-full px-2">
      <div class="flex justify-center mb-8">
        <div>
          <img :src="getCompanyInformation.logo" class="mx-auto mb-4" />
          <h1 class="text-2xl mb-2">Playdate Guest-list</h1>

          <pre v-if="1 === 2">{{ $store.state.bookingInformation.booking }}</pre>
          <p class="text-lg text-gray-800 mb-4">Guest-list for your booking on <span class="font-bold">{{ sessionDate }}</span> at <span class="font-bold">{{ sessionTime }}</span>.</p>
          <p class="text-lg text-gray-800 mb-4">Please update your guest-list with the names of all persons attending so we can easily check them in on the day and so they are included in our fire logs.</p>
          <p class="text-lg text-gray-800 mb-4">If you do not currently know all the names of your guests, you can return to this page by clicking on the same link in your confirmation email.</p>
        </div>
      </div>

      <pre v-if="1 === 2">{{ getTickets }}</pre>
      <pre v-if="1 === 2">{{ compiledTickets }}</pre>

      <div v-for="(ticketType, ticketTypeIndex) in getTickets" v-bind:key="ticketTypeIndex" class="mb-8">
        <h1 class="ml-8 mb-2 font-bold text-xl" data-test-id="ticket-type">{{ ticketType.name }} - ({{ ticketType.quantity }} tickets)</h1>

        <div class="flex justify-between gap-x-2 items-center" v-for="(ticket, ticketIndex) in ticketType.tickets" v-bind:key="ticketIndex">
          <span class="inline-block w-6 text-right">{{ ticketIndex + 1 }}.</span>
          <input class="w-full form-input mb-2" :value="ticket.name" type="text" @change="setTicketName($event, ticketTypeIndex, ticketIndex)" />
        </div>
      </div>

      <div class="my-4 ml-8">
        <div class="mb-4" v-for="(field, fieldIndex) in getInformationFields.form"  v-bind:key="'field_' + fieldIndex">

          <div class="flex flex-col" v-if="field.type === 'NOTES'">
            <span class="inline-block text-lg font-semibold mb-2">{{ field.title }}</span>
            <textarea class="w-full form-input mb-2" :name="field.name" @change="setInformationFieldValue($event, field.name)"></textarea>
          </div>

          <div class="flex flex-col" v-else-if="field.type === 'SELECT_TICKETS'">
            <span class="inline-block text-lg font-semibold mb-2">{{ field.title }}</span>
            <textarea class="w-full form-input mb-2" :name="field.name" @change="setInformationFieldValue($event, field.name)"></textarea>
          </div>
        </div>

        <pre v-if="1 === 2">{{ getInformationFields }}</pre>
      </div>

      <button class="mt-0 w-full px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150" data-test-id="cta-update" @click="ctaUpdate">Update Guestlist</button>
    </div>
  </div>
</template>

<script>
import PlaydateWizard from '@/components/PlaydateWizard.vue'
import { mapGetters } from "vuex";
import {humanReadableDate} from "@/helpers/tools";

export default {
  name: 'TicketsView',

  data() {
    return {
      loading: false,
      checkingLockModal: false,
    }
  },

  components: {
  },

  computed: {
    ...mapGetters([
      'compiledTickets',
      'getInformationFields',

      'getRealmId',
      'getVenueId',
      'getTickets',
      'getBookingId',

      'getCompanyInformation',
    ]),

    sessionDate () {
      return this.humanReadableDate(this.$store.state.bookingInformation?.booking?.info?.session_date)
    },
    sessionTime () {
      return this.$store.state.bookingInformation?.booking?.info?.session_start_time
    }
  },

  methods: {
    humanReadableDate,
    async ctaUpdate () {
      const statusUpdate = await this.$store.dispatch('updateBookings')
      if (statusUpdate) {
        // TODO: go to confirm screen
        this.$router.push({
          name: 'success',
          params: {
            bookingId: this.getBookingId,
            venueId: this.getVenueId,
          }
        })
      } else {
        alert('Error updating your tickets. Please contact the venue.')
      }
    },

    setTicketName(event, ticketTypeIndex, ticketIndex) {
      const name = event.target.value

      const payload = {
        ticketTypeIndex: ticketTypeIndex,
        ticketIndex: ticketIndex,
        name: name
      }

      this.$store.commit('SET_TICKET_NAME', payload)
    },

    setInformationFieldValue(event, name) {
      const value = event.target.value

      const payload = {
        name: name,
        value: value
      }

      this.$store.commit('SET_INFORMATION_FIELD_NAME', payload)
    }
  },

  async mounted () {
    this.loading = true

    this.$store.commit('SET_REALM_ID', this.$route.params.realmId)
    this.$store.commit('SET_VENUE_ID', this.$route.params.venueId)
    this.$store.commit('SET_BOOKING_ID', this.$route.params.bookingId)
    await this.$store.dispatch('companyInfo')
    await this.$store.dispatch('bookingInfo')

    this.loading = false
  }
}
</script>
