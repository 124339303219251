<template>
  <div class="mx-auto">
    <div class="flex flex-col w-full">

      <pre v-if="1 === 2">{{ depositAmount }}</pre>
      <pre v-if="1 === 2">{{ totalPrice }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.getGroupInfo.title }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.getGroupInfo.description }}</pre>

      <pre v-if="1 === 2">{{ $store.state.selectedTickets }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.getTicketsEncoded }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.groupTicketQuantity('children') }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.getRooms }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.getSelectedVenue }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.groupDescription }}</pre>
      <pre v-if="1 === 2">{{ $store.getters.getCompanyInformation }}</pre>

      <!-- Tickets -->
      <div class="mb-8" v-for="(ticket, ticketIndex) in tickets" v-bind:key="'ticket_' + ticketIndex">
        <p class="font-semibold mb-2">How many {{ ticket.name }}</p>

        <select :value="$store.getters.groupTicketQuantity(ticket.id).quantity" @change="$emit('updateTicket', $event.target.value, ticket.id)" class="form-input w-24" :data-test-ticket-selector="ticket.id" >
          <option :value="index" v-for="index in 99" v-bind:key="ticket.id + index" v-show="index >= parseInt(1)">{{ index }}</option>
        </select>
      </div>

      <!-- Date -->
      <div class="mb-8" v-if="$store.getters.getSelectedTickets.length > 0">
        <p class="font-semibold mb-2">Date you want to attend?</p>
        <input type="date" :value="date" @change="ctaHandleDateChange" class="form-input w-48" data-test-id="date-selector" />
        <date-picker v-if="1 === 2" v-model="date" valueType="format" :disabled-week-days="[6, 0]"></date-picker>
      </div>

      <LoadingIndicator title="Loading..." v-if="busyTimes" class="mb-3" />

      <div v-if="date">

        <div class="text-sm my-2 p-2 border-2 border-red-400" v-if="time" data-test-id="countdown-timer">
          <p v-if="!isDisplayMaybeSold" class="">Time left to complete purchase</p> <span class="font-bold">{{ minutes }}:{{ seconds }} min</span>
          <p v-if="isDisplayMaybeSold">You have exceeded the purchase time. The slot is back open for purchase now.</p>
        </div>

        <!-- Rooms -->
        <div class="mb-2">
          <div v-if="$store.getters.availableRoomCount > 0" :data-test-selected-room="$store.state.roomId">

            <div class="" v-if="1 === 2">
              <div v-for="(room, roomIndex) in $store.getters.getAvailableRooms"  v-bind:key="'room_' + roomIndex">
                {{ room.name }}
              </div>
            </div>

            <h3 class="text-2xl font-bold">Rooms</h3>
            <div class="flex flex-col gap-y-8 mb-16">

              <div class="" v-for="(room, roomIndex) in $store.getters.getAvailableRooms" v-bind:key="'room_' + roomIndex">

                <div class="bg-white" :class="$store.state.roomId !== '' && room.id !== $store.state.roomId ? 'opacity-50' : ''" v-if="room.is_available === true">
                  <img :src="room.pictures[0]" alt="" />

                  <div class="p-4">

                    <div class="flex flex-col gap-y-2 mb-8">
                      <h2 class="text-xl font-semibold">{{ room.name }}</h2>
                      <p class="text-base text-gray-500">{{ room.description }}</p>
                    </div>

                    <h3 class="text-sm font-semibold mb-2">Pick a time</h3>
                    <div class="grid grid-cols-4 gap-4">

                      <template v-for="(time, timeIndex) in room.times" >
                        <div :class="$store.state.roomId !== '' && room.id === $store.state.roomId && time.start_time === $store.getters.getTimeStart ? 'bg-blue-500 hover:bg-blue-400 text-white' : 'bg-gray-500 hover:bg-gray-400 text-white'" class="text-center text-lg font-bold px-3 py-2 cursor-pointer" v-bind:key="'time_' + timeIndex" @click="handleRoomSelect(room.id, time)">{{ time.start_time }}</div>
                      </template>

                    </div>

                  </div>

                </div>

              </div>
            </div>
          </div>

          <div v-if="$store.getters.availableRoomCount === 0 && busyTimes === false">
            <p class="text-lg font-semibold">No rooms available that can accommodate current guest numbers. Please adjust the guest numbers.</p>
          </div>
        </div>

        <!-- Extras -->
        <div class="mb-2" v-if="$store.getters.availableRoomCount > 0 && $store.getters.getSelectedRoom.id && $store.getters.getExtras.length > 0">
          <h3 class="text-2xl font-bold mb-2">Extras</h3>
          <ExtraSelector
            :items="$store.getters.getExtras"

            v-on="$listeners"
          />
        </div>
      </div>

      <div class="mt-10">
        <!-- Name -->
        <div class="mb-8" v-if="$store.getters.getSelectedRoom.id">
          <p class="font-semibold mb-2">Your full name</p>
          <input type="text" class="form-input w-full" :value="name" @input="$emit('updateName', $event.target.value)" data-test-id="name" />
        </div>

        <!-- Email -->
        <div class="mb-8" v-if="$store.getters.getSelectedRoom.id">
          <p class="font-semibold mb-2">What is your e-mail?</p>
          <input type="text" class="form-input w-full" :value="email" @input="$emit('updateEmail', $event.target.value)" data-test-id="email" />
        </div>
      </div>

      <!-- Payment -->
      <pre v-if="1 === 2">{{ amountPayableDecimal }}</pre>

      <div class="mb-8" v-if="$store.getters.getSelectedRoom.id">

        <div class="flex-col">
          <div @click="ctaSelectFull" data-test-id="select-full" class="flex justify-between select-none cursor-pointer mb-2 p-4 border-2 border-white bg-white shadow-sm" :data-test-selected="payMode === 'full' ? 'full' : ''" :class="payMode === 'full' ? 'border-2 border-yellow-400' : ''">
            <span>Pay Full Amount</span>
            <PriceRenderer :amount="totalPrice" :currency="currency" reference="full" :data-test-currency="currency" />
          </div>

          <div @click="ctaSelectDeposit" data-test-id="select-deposit" class="flex justify-between select-none cursor-pointer mb-2 p-4 border-2 border-white bg-white shadow-sm" :data-test-selected="payMode === 'deposit' ? 'deposit' : ''" :class="payMode === 'deposit' ? 'border-2 border-yellow-400' : ''">
          <span>Pay Deposit Amount</span>
            <PriceRenderer :amount="depositAmount" :currency="currency" reference="deposit" :data-test-currency="currency" />
          </div>

          <div @click="ctaSelectCustom" data-test-id="select-custom" class="flex justify-between select-none cursor-pointer items-center mb-2 p-4 border-2 border-white bg-white shadow-sm" :data-test-selected="payMode === 'custom' ? 'custom' : ''" :class="payMode === 'custom' ? 'border-2 border-yellow-400' : ''">
            <span>Pay Custom Amount</span>

            <div class="relative rounded-md shadow-sm" xv-if="payMode === 'custom'">
              <div>
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm">{{ currencySign(currency) }}</span>
                </div>
              </div>
              <div>
                <input type="text" :value="renderPrice(amountPayableDecimal)" @keypress="isNumber($event)" @change="updateAmountPayable" name="price" id="price" class="w-32 pl-7 pr-0 sm:text-sm border-gray-300 rounded-md" placeholder="0.00" aria-describedby="price-currency">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span class="text-gray-500 sm:text-sm" id="price-currency">{{ currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="time && timesAvailable === true" class="mb-6 p-4 border-2 border-yellow-400 bg-yellow-50">
        <div class="flex justify-between">
          <p class="font-bold">Amount payable today: </p>
          <PriceRenderer :amount="amountPayableDecimal" :currency="currency" reference="amountPayableDecimal" :data-test-currency="currency" />
        </div>
      </div>

      <div class="mb-10">
        <form method="POST" :action="initiateUrl" ref="form" @submit.prevent="onSubmit">
          <input type="hidden" name="method" value="group_booking">

          <input type="hidden" name="data[email_address]" :value="email" />
          <input type="hidden" name="data[name]" :value="name" />

          <input type="hidden" name="venue_id" :value="venueId" />

          <input type="hidden" name="data[requested_date]" :value="date" />
          <input type="hidden" name="data[session_id]" :value="sessionId" />

<!--          <input v-if="payMode === 'full'" type="hidden" name="data[payment_choice]" :value="'full'" />-->
<!--          <input v-if="payMode === 'deposit'" type="hidden" name="data[payment_choice]" :value="'deposit'" />-->
          <input type="hidden" name="data[payment_choice]" :value="payMode" />
          <input v-if="payMode === 'custom'" type="hidden" name="data[custom_payment_amount]" :value="renderPrice(amountPayableDecimal)" />

<!--
          Bunlar var
          <input type="hidden" name="data[requested_date]" value="2022-12-17">
          <input type="hidden" name="data[email_address]" value="elton.john@example.com ">
          <input type="hidden" name="data[session_id]" value="" placeholder="When empty it is null">
-->

<!--
          Bunlar degisik
          <input type="hidden" name="method" :value="'playdate'" />

-->

<!--
          Bunlar artik yok
          <input type="hidden" name="data[child_count]" :value="children" />
          <input type="hidden" name="data[adult_count]" :value="adults" />

          <input type="hidden" name="data[requested_time]" :value="time" />
          <input type="hidden" name="data[requested_time_end]" :value="timeEnd" />
-->

          <input type="hidden" name="venue_id" :value="$store.getters.getVenueId">
          <input type="hidden" name="data[group_id]" :value="$store.getters.getGroupId">
          <input type="hidden" name="data[room_id]" :value="$store.getters.getRoomId">

          <input type="hidden" name="data[requested_start_time]" :value="$store.getters.getTimeStart">
          <input type="hidden" name="data[requested_end_time]" :value="$store.getters.getTimeEnd">

          <!-- Tickets START -->
          <pre v-if="1 === 2">{{ $store.getSelectedTickets }}</pre>

          <template v-for="(ticket, ticketIndex) in $store.getters.getSelectedTickets">
            <input type="hidden" :name="'data[tickets][' + ticketIndex + '][id]'" :value="ticket.id" v-bind:key="'ticket_id_' + ticketIndex">
            <input type="hidden" :name="'data[tickets][' + ticketIndex + '][quantity]'" :value="ticket.quantity" v-bind:key="'ticket_quantity_' + ticketIndex">
          </template>
          <!-- Tickets END -->

          <!-- Extras START -->
          <template v-for="(extra, extraIndex) in $store.getters.selectedExtras">
            <pre v-if="1 === 2" :key="'extrapre_' + extraIndex">{{ extra }}</pre>
            <input type="hidden" :name="'data[extras][' + extraIndex + '][id]'" :value="extra.id" v-bind:key="'extra_id_' + extraIndex">
            <input type="hidden" :name="'data[extras][' + extraIndex + '][quantity]'" :value="extra.quantity" v-bind:key="'extra_quantity_' + extraIndex">
          </template>
          <!-- Extras END -->

          <input type="hidden" name="tip" :value="$store.getters.getTicketsEncoded">

          <input type="hidden" name="data[fail_url]" :value="currentUrl" />

          <div v-if="!$store.getters.getName || !$store.getters.getEmail" @click="runValidation">
            <div class="opacity-60 text-center t-0 w-full px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition ease-in-out duration-150 cursor-pointer">Please complete your details</div>
          </div>

          <div v-if="$store.getters.getName && $store.getters.getEmail">
            <button type="submit" class="mt-0 w-full px-4 py-2 border border-transparent font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150 cursor-pointer">Proceed to payment</button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Dinero from 'dinero.js'
import ExtraSelector from "@/components/ExtraSelector";
import LoadingIndicator from "./LoadingIndicator";
import PriceRenderer from "./PriceRenderer";
import moment from "moment";

export default {
  name: 'PlaydateWizard',

  props: {
    name: {
      type: String,
    },
    email: {
      type: String,
    },
    date: {
      type: String,
    },
    time: {
      type: String,
    },
    timeStart: {
      type: String,
    },
    timeEnd: {
      type: String,
    },
    sessionId: {
      type: String,
    },
    venueId: {
      type: String,
    },
    initiateUrl: {
      type: String,
    },
    minChild: {
      type: Number
    },
    maxChild: {
      type: Number
    },
    maxAdults: {
      type: Number
    },
    children: {
      type: Number
    },
    adults: {
      type: Number
    },
    times: {
      type: Array
    },
    busyTimes: {
      type: Boolean
    },

    tickets: {
      type: Array
    },

    totalPrice: {
      type: Number
    },
    depositAmount: {
      type: Number
    },
    currency: {
      type: String
    }
  },

  data() {
    return {
      currentUrl: window.location.href,

      selectedDate: '',

      payMode: 'full',
      amountPayableDecimal: 0.0,

      isRunning: false,

      interval: null,
      isDisplayMaybeSold: false,

      countdownSeconds: 300,
      minutes: '',
      seconds: '',
    }
  },

  components: {
    DatePicker,
    ExtraSelector,
    LoadingIndicator,
    PriceRenderer
  },

  computed: {
    timesAvailable () {
      let isAvailable = false
      this.times.forEach(time => {
        if (time.is_available === true) {
          isAvailable = true
        }
      })

      return isAvailable
    },

    isUnavailableDueToInAdvance () {
      let result = false

      this.times.forEach(time => {
        if (time.unavailability_reasons.is_in_advance_range) {
          result = true
        }
      })

      return result
    }
  },

  methods: {
    onSubmit () {
      this.currentUrl = window.location.href

      alert(this.currentUrl)
      this.$refs.form.submit();
    },

    ctaHandleDateChange($event) {
      const date = $event.target.value

      switch (date) {
        case '2023-04-01':
        case '2023-04-02':
        case '2023-04-08':
        case '2023-04-09':
        case '2023-04-15':
        case '2023-04-16':
        case '2023-04-22':
        case '2023-04-23':
        case '2023-04-29':
        case '2023-04-30':
          // this.date = moment().format('YYYY-MM-DD')
          alert('Can not select weekends.')
          return
          break;

        default:
      }

      this.$emit('updateDate', date)
    },

    renderPrice (amountInCents) {
      if (!amountInCents) {
        return
      }

      const total = Dinero({ amount: amountInCents, currency: this.currency, precision: 2 })

      const priceRendered = total
          .setLocale('gb-GB')
          .toFormat('0.00')

      return priceRendered
    },

    runValidation () {
      let errors = 'Error, please complete following steps:'
      errors += "\n"
      if (!this.$store.getters.getName) {
        errors += "\n - You need to fill out your name."
      }

      if (!this.$store.getters.getEmail) {
        errors += "\n - You need to fill out your e-mail."
      }

      alert(errors)
    },

    async handleRoomSelect (roomId, time) {
      await this.$emit('updateRoomAndTime', roomId, time)
    },

    isNumber (evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    updateAmountPayable ($event) {
      const newAmount = parseFloat($event.target.value) * 100

      const depositDecimal = this.depositAmount
      const totalDecimal = this.totalPrice
      console.log('newAmount', newAmount, depositDecimal, totalDecimal)

      if (newAmount < depositDecimal) {
        this.amountPayableDecimal = 0
        this.amountPayableDecimal = depositDecimal
      } else if (newAmount > totalDecimal) {
        this.amountPayableDecimal = this.totalPrice
      } else {
        this.amountPayableDecimal = newAmount
      }
    },

    ctaSelectDeposit () {
      this.payMode = 'deposit'
      this.amountPayableDecimal = this.depositAmount
    },

    ctaSelectFull () {
      this.payMode = 'full'
      this.amountPayableDecimal = this.totalPrice
    },

    ctaSelectCustom () {
      this.payMode = 'custom'
    },


    currencySign (currency) {
      let sign = ''
      switch (currency) {
        case 'GBP':
          sign = '£'
          break
      }

      return sign
    },

    selectTime (loopTime) {
      this.$emit('updateTime', loopTime)
      this.countdownTimer()
    },

    timeColour (loopTime) {
      let finalClass = ''

      if (loopTime.is_available === true && this.time === loopTime.time && loopTime.is_lock_open_to_me === true) {
        finalClass += ' ' + ' border-yellow-800 bg-yellow-200 text-yellow-600 text-white'
      } else if (loopTime.is_available === false && this.time !== loopTime.time) {
        finalClass += ' ' + ' opacity-30 cursor-not-allowed line-through'
      } else {
        finalClass += 'opacity-60 border-blue-800 bg-blue-400 text-white'
      }

      // [
      //   time == loopTime.time ? 'bg-yellow-200' : '',
      //   loopTime.is_available ? 'hover:bg-yellow-100' : 'cursor-not-allowed line-through bg-red-200 text-red-700'
      // ]"
      return finalClass
    },

    // Countdown
    countdownTimer () {
      if (this.countdownSeconds > 0) {
        if (!this.interval) {
          this.interval = setInterval(() => {
            if (this.countdownSeconds > 0) {
              this.countdownSeconds -= 1
            } else {
              clearInterval(this.interval)
              this.isDisplayMaybeSold = true
            }
          }, 1000)
        }
      } else {
        // this.ctaGoToSlots()
      }
    },
  },

  watch: {
    // countdownSeconds (newValue, oldValue) {
    //   const newSeconds = newValue
    //   const minutes = Math.floor(newSeconds / 60)
    //   let seconds = newSeconds - minutes * 60
    //   if (seconds < 10) {
    //     seconds = '0' + seconds
    //   }
    //   this.minutes = minutes
    //   this.seconds = seconds
    // },
    isCompanyInformationLoaded (newValue, oldValue) {

    },


  },

  mounted () {
    if (this.date) {
      this.selectedDate = this.date
    }

    this.amountPayableDecimal = this.depositAmount
  }
}
</script>

<style scoped>
</style>
