// import posthog from "posthog-js";

import _ from "lodash";
import moment from "moment";

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

// export function posthogCapture (key, value) {
//   const envName = process.env.VUE_APP_ENVIRONMENT_NAME
//   console.log('envName', envName)
//
//   if (envName === 'production') {
//     posthog.capture(key, value)
//   } else {
//     console.log('Posthog Capture:', key, value)
//   }
// }
//
// export function posthogIdentify (email, properties) {
//   const envName = process.env.VUE_APP_ENVIRONMENT_NAME
//   console.log('envName', envName)
//
//   if (envName === 'production') {
//     posthog.identify(email, properties)
//   } else {
//     console.log('Posthog Identify:', email, properties)
//   }
// }

export function hasFrameParameters () {
  const params = new URLSearchParams(window.location.search)
  const hasModeParam = params.has('mode')

  return hasModeParam
}

export function iFrameResizeMessage (referenceElement) {
  if (hasFrameParameters() === true) {
    console.log('iFrame - reference element', referenceElement)

    const width = referenceElement.scrollWidth
    const height = referenceElement.scrollHeight

    const message = 'outersizes:' + width + ':' + height
    console.log('Sending Message:', message)

    parent.postMessage(message, '*')
  } else {
    console.log('iFrame - No frame parameter detected, no need to resize.')
  }
}

export function humanReadableDate (date) {
  if (!date) {
    return ''
  }

  return moment(date).format('DD.MM.YYYY')
}

export function normaliseName (name) {
  let nameCleaned = _.startCase(name.toLowerCase())

  // Pay attention to the space AFTER the string
  nameCleaned = _.replace(nameCleaned, 'Mrs ', '')
  nameCleaned = _.replace(nameCleaned, 'Miss ', '')
  nameCleaned = _.replace(nameCleaned, 'Mr ', '')

  return nameCleaned
}
