<template>
  <div :data-test-id="reference">
    <span>{{ totalPrice }}</span>
  </div>
</template>

<script>
import Dinero from 'dinero.js'

export default {
  name: "PriceRenderer",

  props: {
    amount: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: 'GBP'
    },
    reference: {
      type: String,
      default: ''
    },
  },

  computed: {
    totalPrice () {
      const total = Dinero({ amount: this.amount, currency: this.currency, precision: 2 })

      const priceRendered = total
          .setLocale('gb-GB')
          .toFormat('$0.00')

      return priceRendered
    }
  },
}
</script>

<style scoped>

</style>
