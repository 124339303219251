import _ from "lodash";
import Vue from "vue";
import getters from './getters'

export default {
  SET_LOCK_SESSION_ID(state, sessionId) {
    state.sessionId = sessionId
    localStorage.setItem('sessionId', sessionId)
  },

  SET_PLAYDATE_TIMES(state, payload) {
    state.availableTimes = payload
  },

  SET_ROOMS(state, payload) {
    state.rooms = payload.group_booking.rooms
  },

  // Extras
  SET_EXTRAS(state, payload) {
    state.extras = payload
  },

  UPDATE_SELECTED_EXTRAS_QUANTITIES_WITH_NEW_TICKET_QUANTITIES (state, payload) {
    const extras = payload.extras
    const selectedExtras = payload.selectedExtras

    selectedExtras.forEach((extra, extraIndex) => {
      const rule = _.find(extras, { id: extra.id })

      selectedExtras[extraIndex].quantity = rule.rulesOTF.def
    })

    Vue.set(state, 'selectedExtras', selectedExtras)
  },

  EXTRA_ADD_TO_SELECTED (state, product) {
    const selectedExtras = _.cloneDeep(state.selectedExtras)
    if (!_.find(selectedExtras, { id: product.id })) {
      selectedExtras.push({
        id: product.id,
        quantity: product.rulesOTF.def,
        price: product.price,
      })
    }

    state.selectedExtras = selectedExtras
  },

  EXTRA_REMOVE_FROM_SELECTED (state, product) {
    const selectedExtras = _.cloneDeep(state.selectedExtras)
    const foundIndex = _.findIndex(selectedExtras, { id: product.id })
    if (foundIndex > -1) {
      selectedExtras.splice(foundIndex, 1)

      state.selectedExtras = selectedExtras
    }
  },

  EXTRA_INCREASE_QUANTITY (state, product) {
    const selectedExtras = _.cloneDeep(state.selectedExtras)
    const foundIndex = _.findIndex(selectedExtras, { id: product.id })
    // console.log('INCREASE_QUANTITY', foundIndex, product)
    if (foundIndex > -1) {
      // max calculations
      const currentQuantity = selectedExtras[foundIndex].quantity
      if (currentQuantity < product.max) {
        selectedExtras[foundIndex].quantity += 1
        state.selectedExtras = selectedExtras
      }
    }
  },

  EXTRA_DECREASE_QUANTITY (state, product) {
    const selectedExtras = _.cloneDeep(state.selectedExtras)
    const foundIndex = _.findIndex(selectedExtras, { id: product.id })
    // console.log('DECREASE_QUANTITY', foundIndex, product)
    if (foundIndex > -1) {
      // min calculations
      const currentQuantity = selectedExtras[foundIndex].quantity
      if (currentQuantity > product.min) {
        selectedExtras[foundIndex].quantity -= 1
        state.selectedExtras = selectedExtras
      }
    }
  },


  SET_GROUP_INFO(state, payload) {
    state.groupInfo = payload
  },

  SET_COMPANY_DETAILS(state, payload) {
    state.company = payload
    state.isCompanyLoaded = true
  },

  SET_VENUE_INFORMATION(state, payload) {
    state.venues = payload
  },

  SET_SELECTED_ROOM(state, roomId) {
    state.roomId = roomId
  },

  SET_SELECTED_EXTRA(state, payload) {
    const extraId = payload.extraId
    const quantity = payload.quantity

    const selectedExtras = _.cloneDeep(state.selectedExtras)

    const indexOfExtra = selectedExtras.findIndex(extra => extra.id === extraId)
    if (indexOfExtra > -1) {
      selectedExtras[indexOfExtra].quantity = quantity
    } else {
      selectedExtras.push({
        id: extraId,
        quantity: quantity
      })
    }

    Vue.set(state, 'selectedExtras', selectedExtras)
  },

  SET_SELECTED_TICKET(state, payload) {
    const ticketId = payload.ticketId
    const quantity = payload.quantity

    const selectedTickets = _.cloneDeep(state.selectedTickets)

    const indexOfTicket = selectedTickets.findIndex(ticket => ticket.id === ticketId)
    if (indexOfTicket > -1) {
      selectedTickets[indexOfTicket].quantity = quantity
    } else {
      selectedTickets.push({
        id: ticketId,
        quantity: quantity
      })
    }

    Vue.set(state, 'selectedTickets', selectedTickets)
  },

  SET_SELECTED_DATE(state, date) {
    state.date = date
  },
  SET_SELECTED_TIME(state, time) {
    state.time_start = time.time_start
    state.time_end = time.time_end
  },
  SET_NAME(state, name) {
    state.name = name
  },
  SET_EMAIL(state, email) {
    state.email = email
  },

  SET_REALM_ID(state, realmId) {
    state.realmId = realmId
  },
  SET_VENUE_ID(state, venueId) {
    state.venueId = venueId
  },
  SET_GROUP_ID(state, groupId) {
    state.groupId = groupId
  },
  SET_BOOKING_ID(state, bookingId) {
    state.bookingId = bookingId
  },

  SET_BOOKING_INFORMATION(state, bookingInformation) {
    state.bookingInformation = bookingInformation
  },

  SET_TICKETS(state, tickets) {
    Vue.set(state, 'tickets', tickets)
  },

  SET_INFORMATION_FORM(state, payload) {
    Vue.set(state, 'informationFields', payload)
  },

  SET_BOOKING_INFORMATION_LOADED (state) {
    state.isBookingInfoLoaded = true
  },

  SET_TICKET_NAME(state, payload) {
    const tickets = _.cloneDeep(state.tickets)

    const ticketTypeIndex = payload.ticketTypeIndex
    const ticketIndex = payload.ticketIndex
    const name = payload.name
    tickets[ticketTypeIndex].tickets[ticketIndex].name = name

    Vue.set(state, 'tickets', tickets)
  },

  SET_INFORMATION_FIELD_NAME(state, payload) {
    const informationFields = _.cloneDeep(state.informationFields)

    const name = payload.name
    const value = payload.value

    const indexOfExtra = informationFields.responses.findIndex(field => field.name === name)
    if (indexOfExtra > -1) {
      informationFields.responses[indexOfExtra].value = value
    } else {
      informationFields.responses.push({
        name: name,
        value: value
      })
    }

    Vue.set(state, 'informationFields', informationFields)
  },

  SET_RULES_BASED_ON_DATE_AND_TIME(state) {
    _.forEach(state.availableTimes, time => {
      if (time.time === state.time) {
        state.minChild = time.min_child
        state.maxChild = time.max_child
      }
    })
    // filter date
    // filter rules
  },
}
