<template>
  <div>
    <div v-for="(item, itemIndex) in items" v-bind:key="itemIndex" :data-test-item-id="item.id" :data-test-item-quantity="selectedExtra(item.id).quantity">
      <div class="bg-white shadow-md p-4 mb-2">
        <div class="flex flex-col justify-start gap-x-6 mb-2">
          <div class="items-center mb-2">
            <img class="mb-1" :src="item.pictures[0]" alt="" />
            <h2 class="text-base font-semibold">{{ item.name }}</h2>
            <p class="text-sm">Only <PriceRenderer :amount="item.price.amount" :currency="'GBP'" /></p>
            <p class="text-base text-gray-500 whitespace-pre-wrap">{{ item.saleText }}</p>
          </div>
        </div>

        <div v-if="!isExtraSelected(item.id)" class="">
          <div class="button justify-self-end sm:w-1/3 text-white bg-green-400 cursor-pointer" @click="$emit('addItem', item)">Add</div>
        </div>

        <div v-if="isExtraSelected(item.id)" class="">
          <div class="button justify-self-end sm:w-1/3 text-white bg-red-400 cursor-pointer" @click="$emit('removeItem', item)">Remove</div>

          <div class="mt-10 flex justify-between items-center">
            <div>
              <span class="mr-2 select-none cursor-pointer bg-red-200 text-3xl text-red-600 px-6 py-1" @click="$emit('decreaseItem', item)">-</span>
            </div>

            <div class="">
              <span class="mr-2">Selected Quantity</span>
              <span class="mr-2">{{ selectedExtra(item.id).quantity }}</span>
            </div>

            <div>
              <span class="mr-2 select-none cursor-pointer bg-green-200 text-3xl text-green-600 px-6 py-1" @click="$emit('increaseItem', item)">+</span>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PriceRenderer from "@/components/PriceRenderer";

export default {
  name: 'ExtraSelector',

  props: {
    items: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    ...mapGetters([
      'isExtraSelected',
      'selectedExtra'
    ])
  },

  components: {
    PriceRenderer,
  },
}
</script>

<style scoped>
.button {
  @apply px-4 py-2 text-center select-none;
}
</style>
