<template>
  <router-view />
</template>

<script>
import '@/assets/style.css'

export default {
  name: 'App',
}
</script>

<style>
 body {
   @apply bg-gray-100;
 }
</style>
