import {
  getBookingInformation,
  getCompanyInformation, getGroupInfo, getPaymentResult,
  getPlaydateVisitTimes, getRooms,
  requestTimeLock,
  setBookingInformation
} from "@/api";

export default {
  recoverSessionId ({ getters, commit }) {
    let sessionId = getters.getLockSessionId
    if (!sessionId) {
      sessionId = localStorage.getItem('sessionId')
      commit('SET_LOCK_SESSION_ID', sessionId)
    }
  },

  async updateBookings ({ getters, commit }) {
    const statusUpdate = await setBookingInformation(getters.getVenueId, getters.compiledTickets, getters.getBookingId)
    return statusUpdate
  },

  async companyInfo ({ getters, commit }) {
    const companyInformation = await getCompanyInformation(getters.getRealmId)

    commit('SET_COMPANY_DETAILS', companyInformation.company)
    commit('SET_VENUE_INFORMATION', companyInformation.venues)
  },

  async bookingInfo ({ getters, commit }) {
    const bookingInformation = await getBookingInformation(getters.getVenueId, getters.getBookingId)
    console.log('bookingInformation', bookingInformation)


    commit('SET_BOOKING_INFORMATION', bookingInformation)
    commit('SET_TICKETS', bookingInformation.booking.tickets)
    commit('SET_INFORMATION_FORM', bookingInformation.additional_information)
    commit('SET_BOOKING_INFORMATION_LOADED')
  },

  async fetchTimes ({ getters, commit }, payload) {
    const date = payload.date
    const deleteSessionLock = payload.deleteSessionLock

    const playdateTimes = await getPlaydateVisitTimes (getters.getVenueId, date, getters.getLockSessionId, deleteSessionLock)

    commit('SET_PLAYDATE_TIMES', playdateTimes.playdate.times)
  },

  async requestLock ({ getters, commit }) {
    const statusTimelock = await requestTimeLock(getters.getVenueId, getters.getDate, getters.getTimeStart, getters.getGroupId, getters.getRoomId, getters.getLockSessionId)
    if (!statusTimelock) {
      return false
    } else {
      commit('SET_LOCK_SESSION_ID', statusTimelock.data.session_id)

      return true
    }
  },

  async fetchGroupInfo ({ getters, commit }, payload) {
    const info = await getGroupInfo(payload.venueId, payload.groupId)

    commit('SET_GROUP_INFO', info)
  },

  async fetchRoomList ({ state, getters, commit }) {
    // try to update room list
    const date = getters.getDate
    const groupId = getters.getGroupId
    const venueId = getters.getVenueId
    const ticketsEncoded = getters.getTicketsEncoded
    const sessionId = getters.getLockSessionId

    console.log('fetchRoomList - sessionId:', sessionId)

    if (date !== '' && groupId !== '' && venueId !== '' && ticketsEncoded !== '') {
      const listRooms = await getRooms(venueId, groupId, date, sessionId, ticketsEncoded)
      commit('SET_ROOMS', listRooms)
    }
  },

  // Check if payment has been made
  async paymentCheck ({ getters, commit }, referenceId) {
    const statusPayment = await getPaymentResult(referenceId)
    return statusPayment
  },
}
