<template>
  <div class="max-w-xs mx-auto">
    <div v-if="loading">
      <p>Loading...</p>
    </div>

    <div v-if="!loading" class="w-full">
      <div class="flex justify-center mb-8">
        <div>
          <h1 class="text-2xl">{{ getCompanyInformation.name }}</h1>
        </div>
      </div>

      <div class="text-center mb-4">
        <img :src="company.logo" class="mx-auto mb-4" />
        <h1 class="text-2xl bold-bold">{{ company.name }}</h1>
      </div>

      <div v-if="displayBooked">
        <p class="text-lg mb-2">Your <span class="font-bold">{{ amount }}</span> payment has been successful.</p>
        <p class="text-lg mb-2">Your booking confirmation has been emailed to <span class="font-bold">{{ email }}</span>.</p>
        <p class="text-lg mb-2">We have also sent a link to your guest list.</p>
        <p class="text-lg mb-2">Please add the names of guests so we can quickly check them in on arrival (you can amend the list at any time by clicking on the same link).</p>
        <hr class="my-6" />
        <p v-html="companyInfo"></p>
      </div>

      <div v-if="displayCredited && 1 === 2">
        <p class="text-lg mb-2">Unfortunately the transaction was not completed in time and <span class="underline">the requested slot has since been booked by another customer</span>.</p>
        <p class="text-lg mb-2">Our Credit Card Processing Service has already taken your payment of £{{ amount }} however we have sent you a credit to {{ email }} so you can rebook for an alternative date / time.</p>
        <hr class="my-6" />
        <p v-html="companyInfo"></p>
      </div>

      <div v-if="displayCredited">
        <p class="text-lg mb-2">Unfortunately the transaction was not completed in time and <span class="underline">the requested slot has since been booked by another customer</span>.</p>
        <p class="text-lg mb-2">Our Credit Card Processing Service has already taken your payment, however we have sent you a credit to {{ email }} so you can rebook for an alternative date / time.</p>
        <hr class="my-6" />
        <p v-html="companyInfo"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'PaymentCheckView',

  data() {
    return {
      loading: false,

      booking: {
        email_address: '',
      },
      company: {
        name: '',
        logo: '',
        signature: ''
      },
      price: {
        amount: 0,
        amount_decimal: "0.00",
        currency: 'GBP',
      },

      displayBooked: false,
      displayCredited: false,

      intervalPaymentCheck: null,
    }
  },

  components: {
  },

  computed: {
    ...mapGetters([
      'getCompanyInformation',
    ]),

    amount () {
      let result = ''
      switch (this.price.currency) {
        case 'GBP':
          result = '£' + this.price.amount_decimal
          break

        default:
          result = this.price.amount_decimal + ' ' + this.price.currency
      }

      return result
    },

    email () {
      return this.booking.email_address
    },

    companyInfo () {
      return this.company.signature
    }
  },

  methods: {
  },

  async mounted () {
    this.loading = true

    // Check if booking or voucher has been created

    // this.$store.commit('SET_REALM_ID', this.$route.params.realmId)
    // this.$store.commit('SET_VENUE_ID', this.$route.params.venueId)
    // this.$store.commit('SET_BOOKING_ID', this.$route.params.bookingId)
    // await this.$store.dispatch('companyInfo')
    // await this.$store.dispatch('bookingInfo')

    const referenceId = this.$route.params.referenceId
    this.intervalPaymentCheck = setInterval(async () => {
      const statusPayment = await this.$store.dispatch('paymentCheck', referenceId)
      console.log('statusPayment', statusPayment)

      this.booking = statusPayment.playdate_info?.booking ?? {}
      this.company = statusPayment.playdate_info?.company ?? ''
      this.price = statusPayment.playdate_info?.booking?.price ?? 0

      if (statusPayment.is_result_successful && statusPayment.is_webhook_received) {
        this.displayBooked = true
        this.loading = false
        clearInterval(this.intervalPaymentCheck)
      } else if (!statusPayment.is_result_successful && statusPayment.is_webhook_received) {
        this.displayCredited = true
        this.loading = false
        clearInterval(this.intervalPaymentCheck)
      } else {
        // this.loading = true
      }
    }, 3000)
  }
}
</script>
