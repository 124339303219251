import Vue from 'vue'
import Vuex from 'vuex'

import _ from 'lodash'

import actions from './actions'
import state from './state'
import mutations from './mutations'
import getters from './getters'

import {
  // eslint-disable-next-line no-unused-vars
  getPlaydateVisitTimes,
  getCompanyInformation,
  getBookingInformation,
  requestTimeLock,
  setBookingInformation,
  getPaymentResult, getRooms, getGroupInfo
} from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
  }
})
