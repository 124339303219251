import _ from "lodash";
import {b64EncodeUnicode} from "@/helpers/tools";

export default {

  groupTitle: state => state.groupInfo?.title ?? '',
  groupDescription: state => state.groupInfo?.description ?? '',

  groupTickets: state => state.groupInfo.tickets,
  getSelectedTickets: state => state.selectedTickets ?? [],
  getTotalPriceInCents: (state, getters) => {
    let total = 0

    // get selected time
    const timeStart = _.cloneDeep(state.time_start)

    // get selected tickets
    const selectedTickets = _.cloneDeep(getters.getSelectedTickets)

    // get selected room
    const selectedRoom = _.cloneDeep(getters.getSelectedRoom)

    if (!timeStart || !selectedTickets || !selectedRoom.id) {
      return 0
    }

    // match time to tickets
    const selectedTimeInRoom = _.find(selectedRoom.times, { start_time: timeStart })

    const roomTickets = _.cloneDeep(selectedTimeInRoom.tickets)

    // find selected tickets in room tickets
    // calculate price

    selectedTickets.forEach(selectedTicket => {
      const ticket = _.find(roomTickets, { id: selectedTicket.id })

      total += ticket.total_price.amount
    })

    // Add extras
    const extras = _.cloneDeep(getters.selectedExtras)
    const availableExtras = _.cloneDeep(getters.getExtras)

    extras.forEach(extra => {
      const extraFound = _.find(availableExtras, { id: extra.id })
      const extraPrice = extra.quantity * extraFound.price.amount

      total += extraPrice
    })


    // get all ticket info in selected room
    // find all selected tickets
    // multiply

    // get all extras
    // multiply

    // return calculated amount
    return total;
  },

  getTotalDeposit: (state, getters) => {
    // get selected time
    const timeStart = _.cloneDeep(state.time_start)

    // get selected tickets
    const selectedTickets = _.cloneDeep(getters.getSelectedTickets)

    // get selected room
    const selectedRoom = _.cloneDeep(getters.getSelectedRoom)

    if (!timeStart || !selectedTickets || !selectedRoom.id) {
      return false
    }

    // match time to tickets
    const selectedTimeInRoom = _.find(selectedRoom.times, { start_time: timeStart })

    const deposit = _.cloneDeep(selectedTimeInRoom.price_deposit)
    return deposit;
  },


  // Extras
  isExtraSelected: (state) => (guid) => {
    if (!_.find(state.selectedExtras, { id: guid })) {
      return false
    } else {
      return true
    }
  },
  selectedExtra: (state) => (guid) => {
    const infoExtra = _.find(state.selectedExtras, { id: guid })

    if (!infoExtra) {
      return false
    } else {
      return infoExtra
    }
  },
  selectedExtras: (state) => {
    return state.selectedExtras
  },

  groupTicketQuantity: (state, getters) => (ticketId) => {
    const selectedTickets = _.cloneDeep(state.selectedTickets)

    const result = selectedTickets.find(ticket => ticket.id === ticketId)
    if (result) {
      return result
    } else {
      return {
        id: '',
        quantity: 0
      }
    }
  },

  apiUrl: state => state.API_URL,
  getRealmId: state => state.realmId,
  getVenueId: state => state.venueId,
  getGroupId: state => state.groupId,
  getRoomId: (state, getters) => state.roomId,

  getBookingId: state => state.bookingId,

  getRooms: state => state.rooms ?? [],

  getAvailableRooms: (state, getters) => {
    const rooms = _.cloneDeep(getters.getRooms)
    const availableRooms = _.filter(rooms, { is_available: true }) || []

    const roomsFinal = []

    availableRooms.forEach(room => {
      const availableTimes = _.filter(room.times, { is_available: true }) || []
      if (availableTimes.length > 0) {
        roomsFinal.push(room)
      }
    })

    // const availableRoomsWithAvailableTimes = _.find(availableRooms.times, { is_available: true }) || []

    return roomsFinal
  },
  availableRoomCount: (state, getters) => {
    return getters.getAvailableRooms.length
  },

  getGroupInfo: state => state.groupInfo ?? {},

  _calculateRule: (state, getters) => rule => {
    const selectedTickets = getters.getSelectedTickets
    let valueFinal = 0

    switch (rule.rule) {
      case 'TOTAL_COUNT_OF_TICKETS':
        rule.tickets.forEach(ticket => {
          const ticketFound = _.find(selectedTickets, { id: ticket })
          valueFinal += parseInt(ticketFound.quantity)
        })
        break

      case 'FIXED_NUMBER':
        valueFinal = parseInt(rule.value)
        break

      default:
        console.log('Unknown rule:', rule.rule)
    }

    return valueFinal
  },

  getExtras: (state, getters) => {
    // TODO: ticketlara bakarak icindeki rule lari on the fly calculate edip ona gore extras lari min max def lerini yapacak.

    const extrasFinal = []

    const extras = _.cloneDeep(state.groupInfo?.extras ?? [])

    // loop through extras, evaluate rules, add to extras final
    const selectedTickets = getters.getSelectedTickets

    extras.forEach(extra => {
      // min bul
      // max bul
      // def bul
      let min = 0
      let max = 0
      let def = 0

      extra.rules.forEach(rule => {
        switch (rule.type) {
          case 'MIN':
            min = getters._calculateRule(rule)
            break

          case 'MAX':
            max = getters._calculateRule(rule)
            break

          case 'DEFAULT':
            def = getters._calculateRule(rule)
            break

          default:
            console.log('rule type', rule.type)
        }
      })

      extra.rulesOTF = {
        min: min,
        max: max,
        def: def
      }

      extrasFinal.push(extra)
    })

    return extrasFinal
  },
  getExtraById: (state, getters) => extraId => {
    return _.find(getters.getExtras, { id: extraId })
  },

  getTickets: state => state.tickets,
  getInformationFields: (state) => {
    const fields = _.cloneDeep(state.informationFields)

    // TODO: add fields

    return fields
  },

  getSelectedVenue: (state) => {
    return _.find(state.venues, { id: state.venueId })
  },
  getSelectedRoom: (state, getters) => {
    return _.find(getters.getRooms, { id: state.roomId }) ?? false
  },


  getTicketsEncoded: state => {
    const result = b64EncodeUnicode(JSON.stringify(state.selectedTickets))

    return result
  },

  compiledTickets: (state) => {
    const ticketsTypes = _.cloneDeep(state.tickets)
    const finalTickets = []

    ticketsTypes.forEach(ticketType => {
      const id = ticketType.id

      const tickets = ticketType.tickets
      tickets.forEach(ticket => {
        const name = ticket.name
        const dateOfBirth = ticket.date_of_birth

        finalTickets.push({
          id: id,
          person_name: name,
          date_of_birth:dateOfBirth
        })
      })
    })

    return finalTickets
  },

  compiledAnswers: (state) => {
    return state.informationFields.responses
  },

  getCompanyInformation: state => state.company,
  getVenues: state => state.venues,

  getMinChild: state => state.minChild,
  getMaxChild: state => state.maxChild,

  getName: state => state.name,
  getEmail: state => state.email,
  getDate: state => state.date,

  getTime: state => state.time,
  getTimeStart: state => state.time_start,
  getTimeEnd: state => state.time_end,

  getAvailableTimes: state => state.availableTimes,

  getLockSessionId: state => state.sessionId,

  isCompanyInformationLoaded: state => state.isCompanyLoaded,
}
