import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL

// const handleResponse = function (config) {
//   config.headers.Authorization = localStorage.getItem('vue-token')
//
//   // Do something before request is sent
//   return config
// }
//
// const onRejected = function (error) {
//   if (error.status === 401) {
//     // Clear token from local storage
//     localStorage.removeItem('vue-token')
//     localStorage.removeItem('vue-refresh-token')
//     // location.reload()
//   }
//
//   console.log('Error', error)
//   console.log('Window', window)
//
//   // Do something with response error
//   return Promise.reject(error)
// }
//
// const onFullfilled = function (response) {
//   return response
// }
//
// axios.interceptors.request.use(handleResponse, onRejected)
// axios.interceptors.response.use(response => onFullfilled(response), error => onRejected(error.response))

// Realms
const getPlaydateVisitTimes = async function (venueId, date, sessionId, deleteSessionLock) {
  try {
    let url = `${API_URL}/${venueId}/playdate/visit-times/${date}/${sessionId}`
    if (deleteSessionLock) {
      url = `${API_URL}/${venueId}/playdate/visit-times/${date}/${sessionId}/delete`
    }

    const request = await axios.get(url)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}

const getVenuesInformation = async function (realmId) {
  try {
    const request = await axios.get(`${API_URL}/realms/${realmId}/venues`)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}

const getCompanyInformation = async function (realmId) {
  try {
    const request = await axios.get(`${API_URL}/realms/${realmId}/company`)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}

const getBookingInformation = async function (venueId, transactionId) {
  try {
    const request = await axios.get(`${API_URL}/${venueId}/tickets/group-booking/${transactionId}`)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}

const setBookingInformation = async function (venueId, tickets, bookingId) {
  try {
    const payload = {
      transaction_id: bookingId,
      tickets: tickets
    }

    const request = await axios.put(`${API_URL}/${venueId}/tickets/playdate`, payload)
    const response = await request

    if (response.status === 200) {
      return true
    }
  } catch (err) {
    return false
  }
}


const requestTimeLock = async function (venueId, date, time, groupId, roomId, sessionId) {
  try {
    const response = await axios.post(`${API_URL}/${venueId}/group-bookings/lock`, {
      group_id: groupId,
      room_id: roomId,
      selected_date: date,
      selected_time: time,
      session_id: sessionId
    })

    if (response.status === 201) {
      return response.data
    }
  } catch (err) {
    return false
  }
}


const getGroupInfo = async function (venueId, groupId) {
  try {
    let url = `${API_URL}/${venueId}/group-bookings/group-info/${groupId}`

    const request = await axios.get(url)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}

const getRooms = async function (venueId, groupId, date, sessionId, ticketsParameter) {
  try {
    let url = `${API_URL}/${venueId}/group-bookings/${groupId}/rooms/${date}`
    if (sessionId) {
      url += '/' + sessionId
    }
    url += `?tip=${ticketsParameter}`

    console.log('url', url)

    const request = await axios.get(url)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}

const getPaymentResult = async function (referenceId) {
  try {
    const request = await axios.get(`${API_URL}/group-booking/payment/result/${referenceId}`)
    const response = await request

    if (response.status === 200) {
      return response.data.data
    }
  } catch (err) {
    return false
  }
}


export {
  setBookingInformation,
  getPlaydateVisitTimes,
  getVenuesInformation,
  getCompanyInformation,
  getBookingInformation,

  requestTimeLock,

  getGroupInfo,
  getRooms,

  getPaymentResult,
}
