<template>
  <div class="max-w-xs mx-auto">
    <div v-if="loading">
      <p>Loading...</p>
    </div>
    <div v-if="!loading" class="w-full">
      <div class="flex justify-center mb-8">
        <div>
          <img :src="getCompanyInformation.logo" class="mx-auto mb-4" />
          <h1 class="text-2xl">{{ getCompanyInformation.name }}</h1>
        </div>
      </div>

      <div data-test-id="confirm-tickets">
        <p class="text-lg mb-2">Thanks for updating your guest list.</p>
        <p class="text-lg mb-2">You can update / amend your guest list by clicking on the same link in your confirmation email.</p>
        <p class="text-lg mb-2">Please ensure we have all the names of people attending prior to your arrival.</p>
      </div>
    </div>
  </div>
</template>

<script>
import PlaydateWizard from '@/components/PlaydateWizard.vue'
import { mapGetters } from "vuex";

export default {
  name: 'SuccessViews',

  data() {
    return {
      loading: false,
      checkingLockModal: false,
    }
  },

  components: {
  },

  computed: {
    ...mapGetters([
      'getCompanyInformation',
    ]),
  },

  methods: {
  },

  async mounted () {
    this.loading = true

    this.$store.commit('SET_REALM_ID', this.$route.params.realmId)
    this.$store.commit('SET_VENUE_ID', this.$route.params.venueId)
    this.$store.commit('SET_BOOKING_ID', this.$route.params.bookingId)
    await this.$store.dispatch('companyInfo')
    await this.$store.dispatch('bookingInfo')

    this.loading = false
  }
}
</script>
