<template>
  <div class="mx-auto">
    <div v-if="loading">
      <p>Loading...</p>
    </div>

    <div v-else-if="!loading" class="w-full">
      <div class="flex justify-center mb-8">
        <div>
          <img :src="getCompanyInformation.logo" class="mx-auto mb-4" />
          <h1 class="text-2xl text-center">{{ getCompanyInformation.name }}</h1>
          <h2 class="text-lg text-gray-500 text-center mb-4">{{ getSelectedVenue.name }}</h2>

          <h1 class="text-2xl font-bold whitespace-pre-line" v-html="groupTitle"></h1>
          <p class="text-base text-gray-500 whitespace-pre-line" v-html="groupDescription"></p>
        </div>
      </div>

      <PlaydateWizard
        :initiateUrl="initiateUrl"
        :venueId="getVenueId"
        :sessionId="getLockSessionId"
        :name="getName"
        :email="getEmail"
        :minChild="getMinChild"
        :maxChild="getMaxChild"
        :date="getDate"
        :time="getTime"
        :timeStart="getTimeStart"
        :timeEnd="getTimeEnd"
        :times="getAvailableTimes"
        :busyTimes="checkingLockModal === true"
        :tickets="groupTickets"
        :totalPrice="this.$store.getters.getTotalPriceInCents"
        :depositAmount="this.$store.getters.getTotalDeposit.amount"
        :currency="this.getCompanyInformation.currency"

        @updateRoomAndTime="handleRoomUpdate"
        @updateTicket="handleUpdateTicket"
        @updateName="handleNameUpdate"
        @updateEmail="handleEmailUpdate"
        @updateDate="handleDateUpdate"

        @addItem="handleAddItem"
        @removeItem="handleRemoveItem"
        @increaseItem="handleIncreaseQuantity"
        @decreaseItem="handleDecreaseQuantity"
      />
    </div>

    <div class="relative" v-if="isCompanyInformationLoaded">
      <div class="fixed bottom-0 left-0 right-0">

        <div class="flex justify-center items-center gap-x-8 h-16 w-full bg-white border-t-2 border-blue-400">
          <span class="text-lg font-semibold">Price</span>
          <span class="text-lg">{{ totalPriceString }}</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PlaydateWizard from '@/components/PlaydateWizard.vue'
import { mapGetters } from "vuex";

import Dinero from 'dinero.js'
import {b64DecodeUnicode, b64EncodeUnicode} from "@/helpers/tools";
import getters from "@/store/getters";

export default {
  name: 'WizardView',

  data() {
    return {
      loading: true,
      checkingLockModal: false,
    }
  },

  components: {
    PlaydateWizard
  },

  computed: {
    ...mapGetters([
      'isCompanyInformationLoaded',

      'apiUrl',
      'getLockSessionId',

      'getRealmId',
      'getVenueId',

      'groupTitle',
      'groupDescription',
      'groupTickets',

      'getMinChild',
      'getMaxChild',

      'getName',
      'getEmail',
      'getDate',
      'getTime',
      'getTimeStart',
      'getTimeEnd',

      'getAvailableTimes',
      'getCompanyInformation',
      'getSelectedVenue'
    ]),

    initiateUrl () {
      return this.apiUrl + '/realms/' + this.getRealmId + '/payment/initiate'
    },

    totalPriceString () {
      const totalPrice = this.$store.getters.getTotalPriceInCents
      const currency = this.getCompanyInformation.currency

      const totalPriceDinero = Dinero({ amount: totalPrice, currency: currency, precision: 2 })

      const totalRendered = totalPriceDinero
          .setLocale('gb-GB')
          .toFormat('$0.00')

      return totalRendered
    }
  },

  methods: {
    // Extras
    handleAddItem (item) {
      this.$store.commit('EXTRA_ADD_TO_SELECTED', item)

      this.updateCurrentRoute()
    },

    handleRemoveItem (item) {
      this.$store.commit('EXTRA_REMOVE_FROM_SELECTED', item)

      this.updateCurrentRoute()
    },

    handleIncreaseQuantity (product) {
      this.$store.commit('EXTRA_INCREASE_QUANTITY', product)

      this.updateCurrentRoute()
    },

    handleDecreaseQuantity (product) {
      this.$store.commit('EXTRA_DECREASE_QUANTITY', product)

      this.updateCurrentRoute()
    },



    updateCurrentRoute () {
      const query = {}

      if (this.$store.getters.getSelectedTickets !== []) {
        query['tickets'] = b64EncodeUnicode(JSON.stringify(this.$store.getters.getSelectedTickets))
      }

      if (this.$store.getters.selectedExtras !== []) {
        query['extras'] = b64EncodeUnicode(JSON.stringify(this.$store.getters.selectedExtras))
      }

      if (this.$store.getters.getSelectedRoom.id) {
        query['room'] = b64EncodeUnicode(this.$store.getters.getSelectedRoom.id)
      }

      if (this.$store.getters.getDate !== '') {
        query['date'] = b64EncodeUnicode(this.$store.getters.getDate)
      }

      if (this.$store.getters.getTimeStart !== '') {
        query['time_start'] = b64EncodeUnicode(this.$store.getters.getTimeStart)
      }

      if (this.$store.getters.getTimeEnd !== '') {
        query['time_end'] = b64EncodeUnicode(this.$store.getters.getTimeEnd)
      }

      if (this.$store.getters.getEmail !== '') {
        query['email'] = b64EncodeUnicode(this.$store.getters.getEmail)
      }

      if (this.$store.getters.getName !== '') {
        query['name'] = b64EncodeUnicode(this.$store.getters.getName)
      }

      this.$router.push({
        name: 'tickets2',
        params: {
          realmId: this.getRealmId,
          venueId: this.getVenueId,
        },
        query: query
      })
    },

    async handleNameUpdate (name) {
      this.$store.commit('SET_NAME', name)

      this.updateCurrentRoute()
    },

    async handleEmailUpdate (email) {
      this.$store.commit('SET_EMAIL', email)

      this.updateCurrentRoute()
    },

    async handleDateUpdate (date) {
      if (date === '') {
        this.checkingLockModal = true
        return
      }

      this.checkingLockModal = true
      this.$store.commit('SET_SELECTED_DATE', date)

      const payloadFetch = {
        date: date,
        deleteSessionLock: false
      }
      await this.$store.dispatch('fetchTimes', payloadFetch)
      await this.$store.dispatch('fetchRoomList')
      this.$store.commit('SET_SELECTED_ROOM', null)

      this.$store.commit('SET_SELECTED_TIME', '')
      this.checkingLockModal = false

      this.updateCurrentRoute()
    },

    async handleRoomUpdate (roomId, time) {
      this.$store.commit('SET_SELECTED_ROOM', roomId)
      this.$store.commit('SET_SELECTED_TIME', {
        time_start: time.start_time,
        time_end: time.end_time
      })
      // this.updateCurrentRoute()

      // Update Time:
      const isAvailable = time.is_available
      if (!isAvailable) {
        return
      }
      this.checkingLockModal = true

      const statusLock = await this.$store.dispatch('requestLock')
      if (statusLock === true) {
        console.log('Lock successful, new session ID:', this.$store.getters.getLockSessionId)
      }

      if (statusLock === false) {
        console.log('Lock failed, session ID:', this.$store.getters.getLockSessionId)
        alert('The ' + time.time + ' time is not available, please try another time slot.')

        const payloadFetch = {
          date: this.getDate,
          deleteSessionLock: false
        }
        // await this.$store.dispatch('fetchTimes', payloadFetch)
        this.checkingLockModal = false
      } else {

        this.$store.commit('SET_SELECTED_TIME', {
          time_start: time.start_time,
          time_end: time.end_time
        })

        this.$store.commit('SET_RULES_BASED_ON_DATE_AND_TIME')

        // this.$router.push({
        //   name: 'intro',
        //   params: {
        //     realmId: this.getRealmId,
        //     venueId: this.getVenueId,
        //     date: this.getDate,
        //     time: time.time,
        //     children: this.getChildren.toString(),
        //     adults: this.getAdults.toString(),
        //   }
        // })

        const payloadFetch = {
          date: this.getDate,
          deleteSessionLock: false
        }
        // await this.$store.dispatch('fetchTimes', payloadFetch)

        this.checkingLockModal = false
      }

      this.updateCurrentRoute()


      // Fetch rooms
      await this.$store.dispatch('fetchRoomList')
    },

    async handleUpdateTicket (quantity, ticketId) {
      this.$store.commit('SET_SELECTED_TICKET', {
        ticketId: ticketId,
        quantity: quantity
      })

      const payload = {
        extras: this.$store.getters.getExtras,
        selectedExtras: this.$store.getters.selectedExtras
      }
      this.$store.commit('UPDATE_SELECTED_EXTRAS_QUANTITIES_WITH_NEW_TICKET_QUANTITIES', payload)

      this.updateCurrentRoute()

      this.$store.dispatch('fetchRoomList')
    },
  },

  async mounted () {
    this.loading = true

    const realmId = this.$route.params.realmId
    const venueId = this.$route.params.venueId
    const groupId = this.$route.params.groupId

    this.$store.commit('SET_REALM_ID', realmId)
    this.$store.commit('SET_VENUE_ID', venueId)
    this.$store.commit('SET_GROUP_ID', groupId)

    await this.$store.dispatch('companyInfo')
    await this.$store.dispatch('fetchGroupInfo', {
      venueId: this.$route.params.venueId,
      groupId: this.$route.params.groupId
    })

    // Resolve time and date
    const time_start = this.$route.query.time_start
    const time_end = this.$route.query.time_end

    if (time_start && time_end) {
      const payload = {
        time_start: b64DecodeUnicode(time_start),
        time_end: b64DecodeUnicode(time_end),
      }
      this.$store.commit('SET_SELECTED_TIME', payload)
    }

    // Resolve Tickets
    const queryTickets = this.$route.query.tickets
    if (queryTickets) {
      try {
        const selectedTickets = JSON.parse(b64DecodeUnicode(queryTickets))

        selectedTickets.forEach(ticket => {
          this.$store.commit('SET_SELECTED_TICKET', {
            ticketId: ticket.id,
            quantity: ticket.quantity
          })
        })
      } catch (e) {
        console.error(e)
      }
    }

    // Resolve Extras
    const queryExtras = this.$route.query.extras
    if (queryExtras) {
      try {
        const selectedExtras = JSON.parse(b64DecodeUnicode(queryExtras))

        selectedExtras.forEach(extra => {
          this.$store.commit('SET_SELECTED_EXTRA', {
            extraId: extra.id,
            quantity: extra.quantity
          })
        })
      } catch (e) {
        console.error(e)
      }
    }

    // Resolve date
    const queryDate = this.$route.query.date
    if (queryDate) {
      const decodedDate = b64DecodeUnicode(queryDate)
      this.$store.commit('SET_SELECTED_DATE', decodedDate)
    }

    // Resolve Room
    const queryRoom = this.$route.query.room
    if (queryRoom) {
      const decodedRoom = b64DecodeUnicode(queryRoom)
      this.$store.commit('SET_SELECTED_ROOM', decodedRoom)
    }

    // Resolve name
    const queryName = this.$route.query.name
    if (queryName) {
      const decodedName = b64DecodeUnicode(queryName)
      this.$store.commit('SET_NAME', decodedName)
    }

    // Resolve email
    const queryEmail = this.$route.query.email
    if (queryEmail) {
      const decodedEmail = b64DecodeUnicode(queryEmail)
      this.$store.commit('SET_EMAIL', decodedEmail)
    }

    await this.$store.dispatch('recoverSessionId')
    await this.$store.dispatch('fetchRoomList')

    // Recover amount to be paid
    this.loading = false
  }
}
</script>
