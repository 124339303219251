import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/layouts/WizardLayout'
import WizardView from '@/views/WizardView'
import WizardLayout from '@/views/layouts/WizardLayout'
import TicketsView from "@/views/TicketsView";
import SuccessView from "@/views/SuccessView";
import PaymentCheckView from "@/views/PaymentCheckView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/',
    component: WizardLayout,
    children: [
      {
        path: '/payment_check/:referenceId',
        name: 'payment_check',
        components: {
          main: PaymentCheckView
        },
      },
      {
        path: '/:realmId/:venueId/purchase/:date?/:time?/:children?/:adults?', // /:name?/:email?
        name: 'intro',
        components: {
          main: WizardView
        },
      },
      {
        path: '/:realmId/:venueId/tickets/:bookingId',
        name: 'tickets',
        components: {
          main: TicketsView
        },
      },
      {
        path: '/:realmId/:venueId/success/:bookingId',
        name: 'success',
        components: {
          main: SuccessView
        },
      },

      {
        path: '/:realmId/:venueId/:groupId/',
        name: 'tickets2',
        components: {
          main: WizardView
        },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
  });
};

export default router
