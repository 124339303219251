<template>
  <div class="max-w-md mx-auto h-auto px-4 py-6 mt-10 mb-10 border border-gray-200 bg-gray-50">
    <router-view name="main" />
  </div>
</template>

<script>
export default {
  name: "WizardLayout"
}
</script>

<style scoped>

</style>
