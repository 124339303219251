export default {
    API_URL: process.env.VUE_APP_API_URL,

    venueId: '',
    realmId: '',
    groupId: '',
    bookingId: '',
    roomId: '',

    // selected values
    name: '',
    email: '',
    date: '',
    time: '', // 5 min timer?

    time_start: '',
    time_end: '',

    // Session ID for locking
    sessionId: '',

    // Booking Information
    isBookingInfoLoaded: false,

    bookingInformation: {},

    tickets: [],
    informationFields: [],

    rooms: [],
    extras: [],

    isCompanyLoaded: false,
    company: {
        name: '',
        logo: '',
        currency: ''
    },
    venues: [],

    groupInfo: {
        tickets: [],
        extras: [],
    },

    selectedTickets: [],
    selectedExtras: [],

    availableTimes: [],
}
